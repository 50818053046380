<template>
  <main class="main-second">
    <div class="description">
      <div class="description-middle">
        <div class="description-middle-left">
          <img
            src="@/assets/img/description_logo.png"
            alt=""
            class="description-logo"
          />
          <span>海量游戏，点开即玩</span>
          <span class="description-text">不用下载，无需安装，不占内存</span>
        </div>
        <div class="description-middle-right">
          <img src="@/assets/img/description_ui.png" alt="" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "YbHomeMiansecond",
};
</script>

<style lang="scss" scoped>
.description {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description-middle {
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.description-middle-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description-logo {
  width: 100px;
  margin-bottom: 24px;
}

.description-middle-left span:nth-child(2) {
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
}

.description-middle-left span:nth-child(3) {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
  opacity: 0.4;
}

.description-text {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
  opacity: 0.7;
}

.description-middle-right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .description-middle {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description-middle-left {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
  .description-logo {
    width: 90px;
    margin-bottom: 0px;
  }

  .description-middle-right {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
