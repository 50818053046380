<template>
  <div class="home">
    <mainFirst @handleDownload="handleDownload" />
    <mianSecond />
    <mianThree />
  </div>
</template>

<script>
import mainFirst from "./components/mainFirst.vue";
import mianSecond from "./components/mianSecond.vue";
import mianThree from "./components/mianThree.vue";
import useDownload from "@/composables/useDownload";

export default {
  name: "Home",
  components: {
    mainFirst,
    mianSecond,
    mianThree,
  },
  setup() {
    const { handleDownload } = useDownload();
    return {
      handleDownload,
    };
  },
};
</script>

<style lang="scss">
.home {
  height: auto;
}
</style>
