import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import '@/styles/aside.scss'

import { ElButton, ElSelect, ElBacktop, ElMessage } from 'element-plus'
import { CaretTop } from "@element-plus/icons"
import backTop from "@/components/backTop.vue"


const app = createApp(App);
app.component('caret-top', CaretTop)
app.component('backTop', backTop)
app.use(ElButton)
app.use(ElSelect)
app.use(ElBacktop)
app.use(ElMessage)
app.use(store)
app.use(router)
app.mount('#app')
