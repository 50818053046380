export default {
  namespaced: true,
  state: {
    showMask: false
  },
  mutations: {
    SET_SHOWMASK: (state: { showMask: boolean; }, showMask: boolean) => {
      state.showMask = showMask;
    }
  },
  actions: {
    setShowMask({ commit }: any, showMask: boolean) {
      commit('SET_SHOWMASK', showMask)
    }
  },
}
