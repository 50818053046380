// 获取当前屏幕可见区域宽度
export const offsetWid =  document.documentElement.clientWidth;

// 获取当前屏幕可见区域高度
export const offsetHeight = document.documentElement.clientHeight;

// 获取当前网址后面的参数
export function GetParams(Name: string) {
  //获取url值
  var ref = window.location.href;
  var args = ref.split("?");
  var retral = "";
  if (args[0] == ref) /*参数为空*/ {
    return retral;
  }
  var str = args[1];
  args = str.split("&");
  for (var i = 0; i < args.length; i++) {
    str = args[i];
    var arg = str.split("=");
    if (arg.length <= 1) continue;
    if (arg[0] == Name) retral = arg[1];
  }
  return retral;
}

// 判断网址是否在微信下打开
export function isWeiXin(): boolean {
  const ua = window.navigator.userAgent.toLowerCase();
  const match = ua.match(/MicroMessenger/i);
  if (match === null) {
    return false;
  }
  if (match.includes('micromessenger')) {
    return true;
  }
  return false;
}
