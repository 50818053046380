<template>
  <div id="app">
    <div class="mask" v-if="show" @click="handlCloseMask">
      <img src="@/assets/img/guide_ui.png" alt="" class="guide_ui" />
    </div>
    <backTop>
      <Header />
      <div class="main">
        <router-view />
      </div>
      <Footer />
    </backTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";

export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const store = useStore();
    const show = computed(() => store.state.mask.showMask);
    const handlCloseMask = () => {
      store.dispatch("mask/setShowMask", false);
    };
    return {
      show,
      handlCloseMask,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.main {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
}
.mask {
  // display: none;
}
.guide_ui {
  width: 70%;
  margin-left: 30%;
}
@media screen and (max-width: 1024px) {
  .mask {
    // display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
}
</style>
