<template>
  <main class="main-first">
    <div class="container">
      <div class="container-middle">
        <div class="container-middle-left">
          <img src="@/assets/img/container_ui.png" alt="" />
        </div>
        <div class="container-middle-right">
          <div class="container-text">
            <span>海量福利&nbsp;0元畅玩</span>
            <span
              >尽在
              <img src="@/assets/img/name.png" alt="" class="container-logo" />
              App
            </span>
          </div>
          <div class="container-download">
            <div class="container-download-left">
              <div class="container-button" @click="handleDownload">
                <img
                  src="@/assets/img/btn_android.png"
                  alt=""
                  class="btn-logo"
                />
                <span>马上下载</span>
              </div>
              <a
                href="javascript:void(0);"
                disabled="disabled"
                style="pointer-events: none"
              >
                <div class="container-button-no">
                  <img src="@/assets/img/btn_ios.png" alt="" class="btn-logo" />
                  <span>敬请期待</span>
                </div>
              </a>
            </div>
            <div class="container-download-right">
              <div class="qr-code">
                <img src="@/assets/img/qr_code.png" alt="" />
                <span>扫码安装到手机</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import bg from "@/assets/img/bg.png";
export default {
  name: "YbHomeMainfirst",
  setup(props, context) {
    const handleDownload = () => {
      context.emit("handleDownload");
    };
    return {
      bg,
      handleDownload,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-first {
  margin-top: 8vh;
  background-image: url("~@/../src/assets/img/bg.png");
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-middle {
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-middle-left {
  width: 50%;
  height: 100%;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.container-middle-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-text {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-text span:nth-child(1) {
  font-size: 45px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
}

.container-text span:nth-child(2) {
  font-size: 35px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 100;
  color: #272727;
}

.container-logo {
  width: 85px;
  vertical-align: sub;
}

.container-download {
  width: 80%;
  height: 50%;
  display: flex;
  flex-direction: row;
}

.container-download-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.qr-code {
  width: 135px;
  height: 145px;
  background: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qr-code img {
  width: 104px;
}

.qr-code span {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.container-button {
  margin: 10px;
  width: 164px;
  height: 54px;
  background-color: transparent;
  border-radius: 51px;
  border: 2px solid #272727;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #272727;
  cursor: pointer;
}

.container-button-no {
  margin: 10px;
  width: 164px;
  height: 54px;
  border-radius: 51px;
  border: 2px solid #d6b300;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #d6b300;
}

.btn-logo {
  height: 35px;
  margin: 10px;
}

@media screen and (max-width: 1024px) {
  .main-first {
    margin-top: 2vh;
    background-image: url("~@/../src/assets/img/bg_android.png");
  }
  .container-middle {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-middle-left {
    width: 100%;
    height: 60%;
    margin-right: 0px;
    display: flex;
    justify-content: center;
  }

  .container-middle-right {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container-text {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-logo {
    width: 75px;
  }

  .container-text span:nth-child(1) {
    font-size: 30px;
  }

  .container-text span:nth-child(2) {
    font-size: 30px;
  }

  .container-download {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .container-download-left {
    flex-direction: column;
    align-items: center;
  }

  .container-button {
    width: 174px;
    height: 47px;
    border: 3px solid #272727;
    margin: 0px;
  }

  .container-button-no {
    width: 174px;
    height: 47px;
    border: 3px solid #d6b300;
  }

  .container-download-right {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .container-text span:nth-child(1) {
    font-size: 30px;
  }

  .container-text span:nth-child(2) {
    font-size: 25px;
  }

  .container-logo {
    width: 65px;
  }

  .container-button {
    width: 134px;
    height: 44px;
  }

  .container-button-no {
    width: 134px;
    height: 44px;
  }
}
</style>
