<template>
    <header class="header">
        <a href="/" class="header-logo">
            <img :src="logo" alt />
        </a>
        <button class="header-download" @click="handleDownload">下载App</button>
    </header>
</template>

<script>
import name from '@/assets/img/name.png'
import logo from '@/assets/img/logo.png'
import useDownload from '@/composables/useDownload'

export default {
  name: 'YbHomeHeader',
  setup() {
    const { handleDownload } = useDownload()
    return {
      name,
      logo,
      handleDownload
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 8vh;
  background: #272727;
  border: 1px solid #979797;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.header-logo {
  width: 80px;
  margin-left: 10%;
}

.header-logo img {
  width: 100%;
}

.header-download {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header {
    height: 6.5vh;
    display: flex;
    justify-content: space-between;
  }

  .header-logo {
    width: 60px;
    margin-left: 7%;
  }

  .header-download {
    display: block;
    width: 84px;
    height: 84%;
    margin-right: 7%;
    background: #ffffff;
    border-color: #272727;
    border-radius: 19px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #272727;
    font-weight: 800;
    cursor: pointer;
    outline: none;

    &:focus {
      outline: none;
    }
    &::-moz-focus-inner {
      border-color: transparent;
    }
  }
}
</style>
