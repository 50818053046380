import request from "../utils/request";
import { baseURL } from './config'

/**
 * 获取游戏app下载链接
 * @param {*} param0
 * @returns
 */
 export function getDownloadApk(chanelPackageId: string | number) {
  return request({
      url: `${baseURL}/v1/landing-page/getChanelDefaultURL`,
      method: 'get',
      params: {
        chanelPackageId
      }
  })
}
