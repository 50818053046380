const env = process.env.VUE_APP_ENV || 'development'

console.log('-------process.env.VUE_APP_ENV', process.env.VUE_APP_ENV, env)
console.log('-------process.env.NODE_ENV', process.env.NODE_ENV)

const dev = {
  // TODO 跟服务端最终确认各个环境变量值
  baseURL: 'https://wb.evkland.cn',
}

const prop = {
  // TODO 跟服务端最终确认各个环境变量值
  baseURL: 'https://api.iwanbei.com',
}

let config = dev

switch (env) {
  case 'development':
    config = dev
    break;
  case 'production':
    config = prop
    break;
}

export const baseURL = config.baseURL;
