<template>
  <main class="main-three">
    <div class="main-three-container">
      <div class="container-middle-three">
        <div class="container-middle-left">
          <img src="@/assets/img/container_ui_second.png" alt="" />
        </div>
        <div class="container-middle-right">
          <img src="@/assets/img/rocket-icon.png" alt="" class="rocket-logo" />
          <span class="description-text-one">免费畅玩，告别游戏荒</span>
          <span class="description-text-three"
            >休闲解压，新奇脑洞，魔性洗脑，二次元...</span
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "YbHomeMianthree",
};
</script>

<style lang="scss" scoped>
.main-three {
  background-color: rgb(255, 213, 0);
}
.main-three-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-middle-three {
  width: 70%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-middle-left {
  width: 50%;
  height: 100%;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
}

.container-middle-right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rocket-logo {
  width: 100px;
  margin-bottom: 24px;
}

.description-text-one {
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
}

.description-text-three {
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
  opacity: 0.4;
}
@media screen and (max-width: 1024px) {
  .container-middle-three {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .container-middle-left {
    width: 100%;
    height: 60%;
    margin-right: 0px;
    display: flex;
    justify-content: center;
  }
  .container-middle-right {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .rocket-logo {
    width: 90px;
    margin-bottom: 0px;
  }
}
</style>
