import { getDownloadApk } from "@/api/download";
import { GetParams, isWeiXin, offsetWid } from "@/utils/index";
import { useStore } from "vuex"

export default function useDownload() {
  const store = useStore()

  const handleDownload = () => {
    let isWeixin = isWeiXin();
    let params = GetParams("pid") ? GetParams("pid") : '1';
    if (isWeixin && offsetWid <= 1024) {
      store.dispatch('mask/setShowMask', true);
    } else {
      store.dispatch('mask/setShowMask', false);
      getDownloadApk(params)
        .then((res) => {
          if (res && res.data) {
            window.location.href = res.data.url;
          }
        })
        .catch((err) => console.error(err));
    }

  };
  return {
    handleDownload
  }
}


