<template>
    <footer>
        <div class="content">
            <div class="m_link">
                <div class="item item_links">
                    <router-link :to="'/policy'">
                        <span class="link link_first">隐私政策</span>
                    </router-link>
                    <router-link :to="'/userServe'">
                        <span class="link link_second">用户协议</span>
                    </router-link>
                    <router-link :to="'/complain'">
                        <span class="link">侵权投诉</span>
                    </router-link>
                </div>
                <div class="item">
                    <span>不良内容举报邮箱</span>
                    <span>guildunion@163.com</span>
                </div>
                <div class="item">
                    <span>商务合作邮箱</span>
                    <span>guildunion@163.com</span>
                </div>
            </div>
            <div class="record">
                <p>本公司产品适合10周岁以上玩家使用 &nbsp; 未成年人家长监护</p>
                <p class="record_hint">
                    <span>
                        抵制不良游戏 &nbsp; 拒绝盗版游戏 &nbsp; 注意自我保护 &nbsp;
                        谨防受骗上当
                    </span>
                    <span>
                        适度游戏益脑 &nbsp; 沉迷游戏伤身 &nbsp; 合理安排时间 &nbsp;
                        享受健康游戏
                    </span>
                </p>
                <br />
                <!-- <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009901"
                  target="_blank">
                  <p>
                    <span style="display: flex; align-items: center; justify-content: center;">
                        <img src="@/assets/img/logo.png" /> &nbsp;粤公网安备 44010602009901号
                    </span>
                  </p>
                </a> -->
                <a href="http://beian.miit.gov.cn/" target="_blank">
                    <p>粤ICP备18040196号-1 &nbsp;&nbsp;&nbsp;&nbsp; 电话：020-85624842</p>
                </a>
                <p>Copyright © 2020 广州游盟信息技术有限公司 版权所有</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'YbHomeFooter'
}
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  background-color: #272727;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.m_link {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0 8px;
}

.item {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.item .link {
  display: block;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  margin: 5px 0;
  text-decoration: none;
  font-size: 15px;
}

.item span {
  margin: 5px 0;
}

.record {
  margin: 14px 0;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999ff;
}

.record p {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999ff;
}

.record a {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999ff;
}

@media screen and (max-width: 825px) {
  .m_link {
    width: 80%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .item_links {
    display: flex;
    flex-direction: row;
  }

  .item_links .link {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    line-height: 11px;
  }

  .item_links .link_first::after {
    display: block;
    content: '';
    background-color: #cccccc;
    height: 11px;
    width: 1px;
    margin-left: 17px;
    margin-right: 17px;
  }

  .item_links .link_second::after {
    display: block;
    content: '';
    background-color: #cccccc;
    height: 11px;
    width: 1px;
    margin-left: 17px;
    margin-right: 17px;
  }

  .item span {
    font-size: 12px;
    font-weight: 200;
    margin: 0;
  }

  .item:nth-child(2) {
    margin-top: 14px;
    margin-bottom: 7px;
  }

  .record_hint {
    margin: 0 auto;
  }

  .record_hint span:nth-child(2) {
    display: block;
  }
}
</style>
